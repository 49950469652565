<template>
    <div class="login-view">
        <form class="l-padded l-stack l-gap-2" @submit.prevent="handleSubmit">
            <div class="l-inline l-justify-end">
                <WhitelabledLogo height="40" transition />
            </div>

            <template v-if="isResetting">
                <h2>{{ $t('titleResetting') }}</h2>

                <FormInput
                    v-model="$v.email.$model"
                    v-focus
                    :label="$t('email')"
                    :show-required-error="$v.email.$dirty && !$v.email.required"
                    :errors="
                        $v.email.$dirty && !$v.email.email
                            ? [$t('emailInvalid')]
                            : []
                    "
                    required
                />
            </template>

            <template v-else-if="!isLoginSucceed">
                <h1>{{ $t('title') }}</h1>

                <h2>{{ $t('subtitle') }}</h2>

                <span v-if="isLoginFailed" class="form-error">
                    {{ $t('loginFailed') }}
                </span>

                <FormInput
                    v-model="$v.username.$model"
                    v-focus
                    :label="$t('username')"
                    :show-required-error="
                        $v.username.$dirty && !$v.username.required
                    "
                    data-test-username
                    required
                />

                <FormInput
                    v-model="$v.password.$model"
                    type="password"
                    :label="$t('password')"
                    :show-required-error="
                        $v.password.$dirty && !$v.password.required
                    "
                    data-test-password
                    required
                />
            </template>

            <template v-else>
                <div>
                    <p class="form-label">
                        {{ $t('simulateTip') }}
                    </p>

                    <UserSelect
                        v-model="userToSimulate"
                        :exclude="[getCurrentUserId]"
                        show-details
                    />
                </div>
            </template>

            <div class="l-inline l-gap-2 l-center-v l-spread ">
                <div>
                    <button
                        v-if="!isLoginSucceed"
                        type="button"
                        class="link"
                        @click="isResetting = !isResetting"
                    >
                        {{
                            isResetting
                                ? $t('backToLogin')
                                : $t('forgotPassword')
                        }}
                    </button>
                </div>

                <div class="l-inline l-gap-2 l-center-v l-justify-end">
                    <VSpinner
                        v-show="isLoading"
                        :size="24"
                        :speed="1"
                        line-fg-color="black"
                        line-bg-color="transparent"
                    />

                    <div>
                        <AxButton :disabled="isLoading">
                            {{
                                isResetting
                                    ? $t('reset')
                                    : !isLoginSucceed
                                    ? $t('login')
                                    : !userToSimulate
                                    ? $t('skip')
                                    : $t('simulate')
                            }}
                        </AxButton>
                    </div>
                </div>
            </div>
        </form>

        <div
            v-if="!isLoginSucceed && !!linkToPlatformWebsite"
            class="l-padded l-stack l-gap-1"
        >
            <h3>{{ $t('noLoginYet') }}</h3>

            <p>
                <a :href="linkToPlatformWebsite">
                    {{ $t('moreInfo') }}
                </a>
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { email, required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, languageHelper } from '@/utils'
import { commonService } from '@/service/store.service'
import AxButton from '@/components/AxButton'
import FormInput from '@/components/FormInput'
import UserSelect from '@/components/UserSelect'
import WhitelabledLogo from '@/components/WhitelabledLogo'
import Whitelabling from '@/mixins/Whitelabling'

export default {
    name: 'PublicLayout',
    components: {
        AxButton,
        FormInput,
        UserSelect,
        VSpinner,
        WhitelabledLogo,
    },
    mixins: [Whitelabling],
    props: {
        nextUrl: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            email: '',
            isLoading: false,
            isLoginFailed: false,
            isLoginSucceed: false,
            isResetting: false,
            linkToPlatformWebsite: process.env.VUE_APP_PLATFORM_WEBSITE_LINK,
            password: '',
            userToSimulate: null,
            username: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['getCurrentUserId', 'isStaff']),
    },
    methods: {
        ...mapActions('auth', ['loadUserInfo', 'login', 'simulateUser']),
        ...mapActions('common', ['loadData']),
        async handleSubmit() {
            if (this.isResetting) {
                if (!this.isValid()) {
                    return
                }
                try {
                    this.isLoading = true
                    await httpHelper.post('/password-reset/', {
                        email: this.email,
                    })
                    this.isResetting = false
                    this.$notify({
                        title: this.$t('titleResetting'),
                        text: this.$t('resetConfirmation'),
                    })
                } finally {
                    this.isLoading = false
                }
                return
            }

            if (this.isLoginSucceed) {
                if (this.userToSimulate) {
                    await this.simulateUser(this.userToSimulate)
                }

                this.redirect()
                return
            }

            this.isLoginFailed = false
            if (!this.isValid()) {
                return
            }

            try {
                this.isLoading = true
                await this.login({
                    username: this.username,
                    password: this.password,
                })
            } catch {
                this.isLoading = false
                this.isLoginFailed = true
                return
            }

            await this.loadUserInfo()
            this.$root.$i18n.locale = languageHelper.getUsersLanguage()

            if (this.isStaff) {
                await commonService.populateUsers()
                this.isLoading = false
                this.isLoginSucceed = true
            } else {
                this.isLoading = false
                this.redirect()
            }
        },
        isValid() {
            this.$v.$touch()
            return !this.$v.$invalid
        },
        redirect() {
            this.$router.push(this.nextUrl || '/', this.loadData)
        },
    },
    validations() {
        return this.isResetting
            ? {
                  email: {
                      email,
                      required,
                  },
              }
            : {
                  password: {
                      required,
                  },
                  username: {
                      required,
                  },
              }
    },
}
</script>

<i18n>
{
    "en": {
        "backToLogin": "Back to login",
        "email": "Email",
        "emailInvalid": "Invalid E-Mail",
        "forgotPassword": "Forgotten password?",
        "login": "Login",
        "loginFailed": "No user for this username password combination found.",
        "moreInfo": "More information about the platform is available here.",
        "noLoginYet": "No login yet?",
        "password": "Password",
        "reset": "Send reset link",
        "resetConfirmation": "A message with a link to reset the password has been sent to the specified email address.",
        "simulate": "Simulate",
        "simulateTip": "Select user to simulate",
        "skip": "Skip",
        "subtitle": "Please log in.",
        "title": "Welcome.",
        "titleResetting": "Password reset.",
        "username": "Username"
    },
    "de": {
        "backToLogin": "Zurück zum Login",
        "email": "Email",
        "emailInvalid": "Ungültige E-Mail",
        "forgotPassword": "Passwort vergessen?",
        "login": "Einloggen",
        "loginFailed": "Keinen Benutzer für diese Kombination von Benutzernamen und Passwort gefunden.",
        "moreInfo": "Mehr Informationen zur Plattform gibt es hier.",
        "noLoginYet": "Noch kein Login?",
        "password": "Passwort",
        "reset": "Link senden",
        "resetConfirmation": "Eine Nachricht mit einem Link zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.",
        "simulate": "Simulieren",
        "simulateTip": "Benutzer für Simulation auswählen",
        "skip": "Überspringen",
        "subtitle": "Bitte melden Sie sich an.",
        "title": "Willkommen.",
        "titleResetting": "Passwort zurücksetzen.",
        "username": "Benutzername"
    },
    "fr": {
        "backToLogin": "Retour à la connexion",
        "email": "E-mail",
        "emailInvalid": "E-mail invalide",
        "forgotPassword": "Mot de passe oublié ?",
        "login": "Se connecter",
        "loginFailed": "Aucun utilisateur pour cette combinaison nom d'utilisateur-mot de passe n'a été trouvé.",
        "moreInfo": "Plus d'informations sur la plateforme sont disponibles ici.",
        "noLoginYet": "Pas de compte ?",
        "password": "Mot de passe",
        "reset": "Envoyer le lien",
        "resetConfirmation": "Un message contenant un lien pour réinitialiser le mot de passe a été envoyé à l'adresse électronique spécifiée.",
        "simulate": "Simuler",
        "simulateTip": "Selectionner l'utilisateur à simuler",
        "skip": "Skip",
        "subtitle": "Veuillez vous connecter.",
        "title": "Bienvenue.",
        "titleResetting": "Réinitialiser le mot de passe.",
        "username": "Nom d'utilisateur"
    },
    "it": {
        "backToLogin": "Torna al login",
        "email": "E-mail",
        "emailInvalid": "E-Mail non valido",
        "forgotPassword": "Password dimenticata?",
        "login": "Login",
        "loginFailed": "Login non valido.",
        "moreInfo": "Ulteriori informazioni sulla piattaforma sono disponibili qui.",
        "noLoginYet": "Non sei ancora registrato?",
        "password": "Password",
        "reset": "Invia link",
        "resetConfirmation": "All'indirizzo e-mail specificato è stato inviato un messaggio con un link per reimpostare la password.",
        "simulate": "Simulate",
        "simulateTip": "Selezionare l'utente da simulare",
        "skip": "Salta",
        "subtitle": "Effettua il login.",
        "title": "Benvenuto.",
        "titleResetting": "Reimposta password.",
        "username": "Nome utente"
    }
}
</i18n>

<style lang="scss" scoped>
.login-view {
    a,
    .link {
        margin: 0;
        padding: 0;
        display: inline;
        border: none;
        background: none;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: inherit;
        text-align: left;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    & > * {
        &:not(:first-child) {
            border-top: $style-border;
        }
    }
}
</style>
