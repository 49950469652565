<template>
    <button
        :disabled="disabled"
        :class="{ secondary: color === 'secondary' }"
        @click="$emit('click')"
    >
        <VSpinner
            v-if="loading"
            class="spinner"
            :line-bg-color="color === 'primary' ? 'white' : 'black'"
            line-fg-color="transparent"
            size="small"
            :speed="1"
        />

        <slot />
    </button>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

export default {
    name: 'AxButton',
    components: {
        VSpinner,
    },
    props: {
        color: {
            type: String,
            default: 'primary',
            validator(val) {
                return val === 'primary' || val === 'secondary'
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
button {
    padding: 1rem;
    width: 100%;
    border: 2px solid #000;
    border-radius: $br-input;
    background-color: #000;
    font-weight: bold;
    font-size: $fs-input;
    color: #fff;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    &:focus:enabled,
    &:hover:enabled {
        background-color: rgba(0, 0, 0, 0.85);

        &.secondary {
            background-color: rgba(108, 117, 125, 0.05);
            color: #000;
        }
    }

    &:active:enabled {
        background-color: rgba(0, 0, 0, 0.8);

        &.secondary {
            border-color: #000;
            background-color: rgba(108, 117, 125, 0.075);
        }
    }

    &.secondary {
        border: 2px solid #6c757d;
        background-color: #fff;
        color: #6c757d;
    }

    .spinner {
        display: inline-block;
        height: 14px;
        padding-right: 4px;
    }
}
</style>
