<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M12,5.251C7.968,5.184,3.8,8,1.178,10.885a1.662,1.662,0,0,0,0,2.226C3.742,15.935,7.9,18.817,12,18.749c4.1.068,8.258-2.814,10.824-5.638a1.662,1.662,0,0,0,0-2.226C20.2,8,16.03,5.184,12,5.251Z"
        />

        <path
            class="a"
            d="M15.749,12A3.75,3.75,0,1,1,12,8.25,3.75,3.75,0,0,1,15.749,12Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'EyeIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
