<template>
    <label class="form-input">
        <div :class="['form-label', 'l-inline', { required }]">
            {{ label }}

            <IconButton
                v-if="info"
                class="info-button"
                @click="showInfoBox = !showInfoBox"
            >
                <InfoIcon :width="13" :height="13" />
            </IconButton>
        </div>

        <InfoBox v-if="showInfoBox" class="info-box">{{ info }}</InfoBox>

        <div v-if="showRequiredError" class="form-error">
            {{ $t('requiredError') }}
        </div>

        <div v-for="(error, index2) in errors" :key="index2" class="form-error">
            {{ error }}
        </div>

        <slot name="error" />

        <input
            v-if="inputTag === 'input'"
            v-model="inputValue"
            class="l-full-width"
            :class="{ 'form-input--password': type === 'password' }"
            :type="typeInternal"
            v-bind="$attrs"
            @blur="$emit('blur')"
            @input="$emit('input', inputValue)"
        />

        <textarea
            v-else-if="inputTag === 'textarea'"
            v-model="inputValue"
            class="l-full-width"
            :rows="rows"
            :type="type"
            v-bind="$attrs"
            @blur="$emit('blur')"
            @input="$emit('input', inputValue)"
        />

        <button
            v-if="inputTag === 'input' && type === 'password'"
            type="button"
            class="form-input__password-toggle"
            @click="switchPasswordType"
        >
            <EyeIcon
                v-if="typeInternal === 'password'"
                width="18"
                height="18"
            />

            <EyeCrossedIcon v-else width="18" height="18" />
        </button>
    </label>
</template>

<script>
import EyeCrossedIcon from './icons/EyeCrossedIcon'
import EyeIcon from './icons/EyeIcon'
import IconButton from './IconButton'
import InfoBox from './InfoBox'
import InfoIcon from './icons/InfoIcon'

export default {
    name: 'FormInput',
    components: {
        EyeCrossedIcon,
        EyeIcon,
        IconButton,
        InfoBox,
        InfoIcon,
    },
    // Disable automatic attribute inheritance, so that $attrs are
    // passed to the <input>, even if it's not the root element.
    // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        showRequiredError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: 'text',
            // Only allow types that essentially just render text boxes.
            validator(value) {
                return [
                    'email',
                    'number',
                    'password',
                    'search',
                    'tel',
                    'text',
                    'url',
                ].includes(value)
            },
        },
        inputTag: {
            type: String,
            default: 'input',
            validator(value) {
                return ['input', 'textarea'].includes(value)
            },
        },
        info: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        rows: {
            type: Number,
            default: 4,
        },
    },
    data() {
        return {
            inputValue: this.value,
            showInfoBox: false,
            typeInternal: this.type,
        }
    },
    watch: {
        type() {
            this.typeInternal = this.type
        },
        value(value) {
            this.inputValue = value
        },
    },
    methods: {
        switchPasswordType() {
            this.typeInternal =
                this.typeInternal === 'text' ? 'password' : 'text'
        },
    },
}
</script>

<i18n>
{
    "en": {
        "requiredError": "this field can't be empty."
    },
    "de": {
        "requiredError": "Dieses Feld darf nicht leer sein."
    },
    "fr": {
        "requiredError": "Ce champ de peut pas être vide."
    },
    "it": {
        "requiredError": "Questo campo non può essere vuoto."
    }
}
</i18n>

<style lang="scss" scoped>
.form-input {
    position: relative;

    input,
    textarea {
        font-family: 'IBM Plex Sans', sans-serif;
    }

    &--password {
        padding-right: 2rem;
    }

    &__password-toggle {
        position: absolute;
        right: 8px;
        bottom: 8px;
        border: none;
        background: none;
        outline: none;

        svg {
            display: block;
        }
    }
}
</style>
